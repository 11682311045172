import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UiService} from "../../services/ui.service";
import {AsyncPipe, NgIf, NgStyle} from "@angular/common";

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    imports: [
        NgStyle,
        AsyncPipe,
        NgIf
    ]
})
export class LogoComponent implements OnInit, OnChanges {

  @Input() height?: string;

  customStyle: any;
  constructor(public ui: UiService) {
    this.customStyle = {
      'background-color': this.ui.getLogoBackgroundColor() || 'transparent',
      'border': this.ui.getLogoBorderColor() ? 'solid 1px ' + this.ui.getLogoBorderColor() : '',
      'height': this.ui.getLogoHeight()
    }
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['height'].currentValue) {
      this.customStyle.height = this.height
    }
  }

}
